import React, { createContext, useEffect, useState } from 'react';
import secureLocalStorage from './secureLocalStorage';

const PermissionContext = createContext();

export const PermissionProvider = ({ children }) => {
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true); // Track loading state

  useEffect(() => {
    const fetchPermissions = async () => {
      const currentUser = secureLocalStorage.getItem('currentUser');
      if (currentUser && currentUser.user_permissions) {
        const userPermissions = currentUser.user_permissions.reduce((acc, perm) => [...acc, ...perm.permissions], []);
        setPermissions(userPermissions);
      }
      setLoading(false); // Set loading to false after permissions are loaded
    };

    fetchPermissions();
  }, []);

  return (
    <PermissionContext.Provider value={{ permissions, loading }}>
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionContext;
