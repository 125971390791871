export const PERMISSIONS = {
  DASHBOARD: ['Update Dashboard', 'Create Dashboard', 'Read Dashboard', 'Delete Dashboard'],
  PROJECT: ['Create Project', 'Read Project', 'Delete Project', 'Update Project'],
  TEST: ['Create Test', 'Read Test', 'Update Test', 'Delete Test'],
  TEST_PLAN: ['Delete Test Plan', 'Update Test Plan', 'Read Test Plan', 'Create Test Plan'],
  TEST_PLAN_LOGS: ['Delete Test Plan Logs', 'Update Test Plan Logs', 'Read Test Plan Logs', 'Create Test Plan Logs'],
  ROLE: ['Update Role', 'Delete Role', 'Read Role', 'Create Role'],
  USER: ['Delete User', 'Update User', 'Read User', 'Create User'],
  COMPONENT: ['Delete Component', 'Update Component', 'Read Component', 'Create Component'],
  CUSTOM_VARIABLE: ['Delete Custom Variable', 'Update Custom Variable', 'Read Custom Variable', 'Create Custom Variable'],
  TICKET: ['Create Ticket', 'Read Ticket', 'Update Ticket', 'Delete Ticket'],
  DEFECT: ['Create Defect', 'Read Defect', 'Update Defect', 'Delete Defect'],
  CONFIG_VARIABLE: ['Create Config Variable', 'Read Config Variable', 'Update Config Variable', 'Delete Config Variable'],
  TEST_CONFIGURATION: ['Delete Test Configuration', 'Create Test Configuration', 'Read Test Configuration', 'Update Test Configuration'],
};
export const Components = {
  DASHBOARD: 'Dashboard',
  PROJECT: 'Project',
  TEST: 'Test',
  TEST_PLAN: 'Test Plan',
  TEST_PLAN_LOGS: 'Test Plan Logs',
  ROLE: 'Role',
  USER: 'User',
  COMPONENT: 'Component',
  CUSTOM_VARIABLE: 'Custom Variable',
  TICKET: 'Ticket',
  DEFECT: 'Defect',
  CONFIG_VARIABLE: 'Config Variable',
  TEST_CONFIGURATION: 'Test Configuration',
};

 // Normalize the action string to match keys ('create', 'read', 'update', 'delete')
export const ActionKeywords = {
  create: 'Create',
  update: 'Update',
  delete: 'Delete',
};