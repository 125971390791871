import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import PermissionContext from './usePermissions';
import Spinner from '../components/common/Spinner/Spinner';

const ProtectedRoute = ({ element, requiredPermissions }) => {
  const { permissions, loading } = useContext(PermissionContext);

  // If permissions are loading, render a loading state or return null
  if (loading) return <div className='flex h-full justify-content-center align-items-center'><Spinner /></div>; // Or replace with a loading spinner

  // Check if the user has the required permissions
  const hasPermission = requiredPermissions.some(permission => permissions.includes(permission));

  return hasPermission ? element : <Navigate to="/unauthorized" />;
};

export default ProtectedRoute;
