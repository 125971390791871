import React from 'react';
import { Card } from 'primereact/card';
import { PrimeIcons } from 'primereact/api'; // Using react-router-dom for navigation

export default function UnauthorizedPage() {
  return (
    <div className="flex justify-content-center align-items-center" style={{ minHeight: '100%', backgroundColor: '#F3F4F6' }}>
      <Card className="p-shadow-3" style={{ maxWidth: '400px', width: '100%', textAlign: 'center' }}>
        <div className="p-mb-3">
          <i className={`pi ${PrimeIcons.EXCLAMATION_TRIANGLE}`} style={{ fontSize: '3rem', color: '#EF4444' }} />
        </div>
        <h1 className="p-mb-2" style={{ fontSize: '2rem', color: '#1F2937' }}>Unauthorized Access</h1>
        <p style={{ fontSize: '1rem', color: '#6B7280' }}>
          Sorry, you don't have permission to access this page.
        </p>

        <div className="p-mt-4 p-d-flex p-jc-center">
          <svg
            viewBox="0 0 24 24"
            width="120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ color: '#4B5563' }}
          >
            <path
              d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
              stroke="#4B5563"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </Card>
    </div>
  );
}
